<!--
 * @Autor: oops.liu
-->
<template>
  <div class="search-box">
    <navbarbox bg="#fff">
      <van-nav-bar
        title="标题"
        :border="false"
        right-text="取消"
        @click-right="onClickRight"
      >
        <template #title>
          <van-search
            v-model="value"
            placeholder="搜索商品"
            shape="round"
            @search="search"
          />
        </template>
      </van-nav-bar>
    </navbarbox>
    <div class="content-box">
      <p class="search-title">热搜</p>
      <div class="hot-search">
        <p
          class="hot-item"
          v-for="item in hotList"
          :key="item.hotWord"
          @click="jump(item.hotWord)"
        >
          {{ item.hotWord }}
        </p>
      </div>
    </div>
    <div class="search-list-box" v-if="false">
      <scroller>
        <div class="search-list">
          <p class="search-item ui-1px-b" @click="jump">女装</p>
          <p class="search-item ui-1px-b" @click="jump">女装</p>
        </div>
      </scroller>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import native from "@/utils/native.js";
import { HANDLE_BACK } from "@/mixin/mixin_file.js";
import navbarbox from "@/components/common/navbarbox.vue";
export default {
  components: {
    navbarbox,
  },
  props: {},
  data() {
    return { value: "", hotList: [] };
  },
  computed: {},
  methods: {
    ...mapActions({ hotWord: "seller/hotWords" }),
    async getHotWord() {
      this.hotList = await this.hotWord();
    },
    onClickRight() {
      native(HANDLE_BACK,null);
      this.$router.push("/category");
    },
    search(val) {
      this.$router.replace({
        path: "search_list",
        query: {
          val: val,
        },
      });
    },

    jump(val) {
      this.$router.replace({
        path: "/search_list",
        query: { val },
      });
    },
  },
  created() {
    this.getHotWord();
  },

};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
